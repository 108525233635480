.App {
    text-align: center;
}

.App-logo {
    height: 10vmin;
    pointer-events: none;
}

.App-header {
    background-color: #3773e8;
    padding: 0.5px;
    color: white;
}

.App-link {
    color: #61dafb;
}
